import React, { useEffect, useRef, useState } from "react";
import {Link} from 'react-router-dom';
import { colors } from "../../../Utils/colors.ts";
import images from "../../../Utils/images.js";
import { uploadFile } from "../../../Services/index.ts";
import { axiosInstance } from "../../../Config/axiosConfig.ts";
import type from "../../../Config/apiTypeConstants.ts";
import endpoint from "../../../Config/apiEndPointConstants.ts";
import { CircularProgress } from "@mui/material";

const DragNDrop = ({responseJSON, setResponseJSON}) => {
  const csvref = useRef();
  const [fileUpload, setFileUpload] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dragActive, setDragActive] = useState(false);

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (
      e.dataTransfer.files &&
      e.dataTransfer.files[0] &&
      e.dataTransfer.files[0].type === "application/pdf"
    ) {
      setFileUpload(e.dataTransfer.files[0]);
    }
  };

  useEffect(() => {
    if (fileUpload) {
      console.log("file uploaded", fileUpload);
      handleFileUpload();
    }
  }, [fileUpload]);

  const handleFileUpload = async () => {
    if (!fileUpload) return;
    console.log("fileUpload", fileUpload);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", fileUpload);
    for (const [key, value] of formData.entries()) {
      console.log(`${key}:`, value);
    }
    let token = "cskhdncsdgc65rd76wgd3278y387h36gdasuyg";
    console.log("formData", formData);
    try {
      const response = await axiosInstance.post(
        type.OPENAI + endpoint.UPLOADFILE,
        formData,
        {
          headers: {
            Authorization: token,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("response===>", response);
        if (Array.isArray(response?.data?.data?.items) && response.data.data.items?.length > 0) {
          setResponseJSON(response?.data?.data?.items);
          
        } else {
          console.log("response.data is not an array or is empty");
        }
      
    } catch (err) {
      console.log("err===>", err);
    }
    setIsLoading(false);
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  if(isLoading){
    return <CircularProgress></CircularProgress>
  }

  return (
    <>
    
      <div
        className="my-2 d-flex flex-column justify-content-center align-items-center"
        style={{
          width: "100%",
          minHeight: 260,
          border: `2px dashed ${colors.borderwhite}`,
          borderRadius: 12,
          cursor: "pointer",
        }}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragExit={handleDrag}
        onDrop={handleDrop}
        onClick={() => {
          if (!fileUpload) {
            csvref.current.click();
          }
        }}
      >
        <input
          type="file"
          style={{ display: "none" }}
          ref={csvref}
          accept=".pdf"
          value=""
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              setFileUpload(file);
            } else {
              setFileUpload(null);
            }
          }}
        />
        <img
          src={images.UploadGrey}
          style={{
            height: 100,
            width: 100,
            objectFit: "contain",
            marginBlockEnd: 10,
          }}
        ></img>
        <p style={{ fontSize: 16, fontWeight: 600, color: colors.black }}>
          {"Drag & drop file here"}
        </p>
        <p style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
          {
            "You can enrich these insights by uploading your meetings and product data or integrating tools"
          }
        </p>
      </div>
      <div>
      <Link
        to="/feature_requests_list.pdf"
        target="_blank"
        download
        className="my-2"
        style={{
            color: colors.black,
            fontWeight: 700,
            cursor: 'pointer',
            textDecoration: 'none',
        }}
        >
        {'Download sample file'}
        </Link>
      </div>
    </>
  );
};

export default DragNDrop;
