import React from "react";
import { Sidebar } from "../../Components/Sidebar.tsx";
import { PageTitle } from "../../Components/PageTitle.tsx";
import RoadmapTable from "../../Components/RoadmapTable.tsx";

const Roadmap = () => {
  return (
    <div className="page d-flex flex-row justify-content-between align-items-start" style={{
      height:'100vh',
      width:'100%',
      background: 'linear-gradient(180deg, rgba(228,255,200,1) 10%, rgba(255,255,255,1) 70%)',
      overflow: "hidden",
    }}>
      <Sidebar />
      <div className="d-flex flex-column justify-content-start align-items-start" style={{
        flex:1,
        height:'100%',
        paddingInline:40,
        paddingBlock:32,
      }}>
        <PageTitle title="Roadmap" />
        <div className="pe-2 verythinscrollnewinv w-100 d-flex flex-column justify-content-start align-items-center" style={{
          flex:1,
          overflowY: 'auto',
        }}>
          <div className="w-100 my-3">
            <RoadmapTable />
          </div>
          <div className="w-100 my-3">
            <img
              src="../../images/integrations.jpg"
              alt="Integrations"
              style={{
                width: "100%",
                height: "auto",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { Roadmap };
