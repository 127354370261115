import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Auth from './Pages/Auth/index.tsx';
import Dashboard from './Pages/Dashboard/index.tsx';
import { Roadmap } from './Pages/Roadmap/index.tsx';
import { Integrations } from './Pages/Integrations/index.tsx';
import { AuthProvider } from './contexts/AuthContext.tsx';
import ProtectedRoute from './Components/ProtectedRoute.tsx';
import images from './Utils/images.js';
import { colors } from './Utils/colors.ts';

export const App = () => {

  const mobileWidth = window.innerWidth < 992;

  return (
    <>
    <AuthProvider>
      {mobileWidth ? (
        <>
        <div className='d-flex flex-column justify-content-center align-items-center px-4' style={{height:'100dvh',width:'100%'}}>
          <div className='d-flex flex-row justify-content-center align-items-center my-3'>
            <img 
              src={images.LogoMain} 
              style={{
                height:30,
                width:30,
                objectFit:'contain',
                marginInlineEnd:10,
              }}
            />
            <h3 style={{fontSize:24,fontWeight:700,color:colors.black,marginBlock:0}}>
              {'Klarytics'}
            </h3>
          </div>
          <p className='my-3' style={{fontSize:16,fontWeight:400,color:colors.greys04,textAlign:'center'}}>
              {'Klarytics Dashboard is accessible only on desktops and laptops. Please log in and access your dashboard from one of these devices.'}
          </p>
        </div>
        </>
      ):(
        <>
        <Routes>
          <Route path='/*' element={<Auth/>} />
          <Route path='/dashboard' element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }/>
          <Route path='/roadmap' element={
            <ProtectedRoute>
              <Roadmap />
            </ProtectedRoute>
          }/>
          <Route path='/integrations' element={
            <ProtectedRoute>
              <Integrations />
            </ProtectedRoute>
          }/>
        </Routes>
        </>
      )}
    </AuthProvider>
    </>
  );
}


