import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import images from "../Utils/images";
import { colors } from "../Utils/colors.ts";


export default function ResearchTables({responseJSON, changeQuotes}: any) {
  return (
    <TableContainer component={Paper} className="featuresTable" elevation={0}>
      <Table size="small">
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <img 
                      src={images.TickCircleGrey} 
                      style={{
                        height:20,width:20,objectFit:"contain"
                      }}
                    ></img>
                </TableCell>
                <TableCell align="left" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Feature Name</TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Occurences</TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Type</TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Reach Score</TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Impact</TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Confidence</TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Tech Effort</TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>RICE Score</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {responseJSON.map((item, index)=>{
                return(
                    <TableRow key={index}>
                        <TableCell align="center">
                            <img 
                                src={images.CircleFillGreen} 
                                style={{height:16,width:16,objectFit:"contain"}}
                            ></img>
                        </TableCell>
                        <TableCell align="left" style={{fontSize:14,fontWeight:400,color:colors.black,paddingBlock:10,cursor:"pointer"}} onClick={()=>{
                          changeQuotes(index);
                        }}>{item?.featureName}</TableCell>
                        <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.linkblue,paddingBlock:10}}>{item?.numberOfTimesMentioned}</TableCell>
                        <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.black,paddingBlock:10}}>{item?.featureType}</TableCell>
                        <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.linkblue,paddingBlock:10}}>{item?.reachScore}</TableCell>
                        <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.linkblue,paddingBlock:10}}>{item?.impact}</TableCell>
                        <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.linkblue,paddingBlock:10}}>{item?.confidence}</TableCell>
                        <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.linkblue,paddingBlock:10}}>{item?.effortForEngineering}</TableCell>
                        <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.linkblue,paddingBlock:10}}>{item?.finalRiceScore}</TableCell>
                    </TableRow>
                )
            })}
        </TableBody>
        {/* <TableBody>
          {rows.map((row) => (
            <TableRow key={row[0]} className="row">
              <TableCell>{row[0]}</TableCell>
              <TableCell>{row[1]}</TableCell>
              <TableCell>{row[2]}</TableCell>
              <TableCell>{row[3]}</TableCell>
              <TableCell>{row[4]}</TableCell>
              <TableCell>{row[5]}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}
      </Table>
    </TableContainer>
  );
}
