import React, {useState, useEffect} from 'react'
import { colors } from '../../Utils/colors.ts'
import { Button } from '@mui/material'
import DragNDrop from './Components/DragNDrop.jsx'
import MeetingAttended from './Components/MeetingAttended.jsx'
import ResearchTables from '../../Components/ResearchTable.tsx'
import Insights from './Components/Insights.jsx'
import images from '../../Utils/images.js'
import RoadmapTable from '../../Components/RoadmapTable.tsx'
import FeaturesTable from '../../Components/FeautesTable.tsx'
import { useNavigate } from 'react-router-dom';
import Quotes from './Components/Quotes.jsx'

const Body = () => {
    const navigate = useNavigate();
    const [responseJSON, setResponseJSON] = useState([]);
    const [dragDropOpen, setDragDropOpen] = useState(true);
    const [quotesIndex, setQuotesIndex] = useState(0);
    useEffect(() => {
        if(responseJSON?.length !== 0){
            setDragDropOpen(false);
        }else{
            setDragDropOpen(true);
        }
    }, [responseJSON])
  return (
    <>
    <div className='d-flex flex-column justify-content-start align-items-center' style={{
        flex:1,
        height:'100%',
        paddingInline:40,
        paddingBlock:32,
    }}>
        <h2 style={{fontSize:32,fontWeight:800,color:colors.green01,width:'100%'}}>
            {'Dashboard'}
        </h2>
        <h2 style={{fontSize:32,fontWeight:800,color:colors.green01,marginBlock:24}}>
            {'Good morning! Karan'}
        </h2>
        <div className='w-100 verythinscrollnewinv pe-2 mt-3' style={{flex:1,overflowY:'auto'}}>

            {/* Training and research */}
            <div className='py-3 px-4 d-flex flex-column justify-content-start align-items-center w-100 mb-3' style={{
                backgroundColor: '#F7FAFA',
                borderRadius:12,
            }}>
                <div className='d-flex flex-row justify-content-between align-items-center w-100'>
                    <h4 style={{fontSize:20,fontWeight:700,color:colors.black}}>
                        {'Training & Research'}
                    </h4>
                    <Button
                        size='small'
                        variant="contained"
                        style={{
                        textTransform:'none',
                        backgroundColor:colors.green01
                        }}
                        onClick={() => {
                            if(responseJSON?.length>0){
                                setResponseJSON([]);
                            }else{
                                navigate('/integrations')
                            }
                        }}
                    >
                        { dragDropOpen ? 'View all integrations' : 'Upload New File'}
                    </Button>
                </div>
                {dragDropOpen ? (
                    <>
                    <div className='w-100 my-3 d-flex flex-column justify-content-start align-items-center'>
                        <DragNDrop setResponseJSON={setResponseJSON} responseJSON={responseJSON}/>
                    </div>
                    </>
                ) : (
                    <>
                    <div className='w-100 my-3 d-flex flex-column justify-content-start align-items-center'>
                        <ResearchTables responseJSON={responseJSON} changeQuotes={(index)=>{
                            setQuotesIndex(index);
                        }}/>
                        <div className='d-flex flex-row justify-content-between align-items-stretch w-100 mt-3'>
                            <Quotes 
                                containerStyle={{marginInlineEnd:10}}
                                quotesData={responseJSON[quotesIndex]?.customerQuotes}
                                index={0}
                            />
                            <Quotes 
                                containerStyle={{marginInlineEnd:10}}
                                quotesData={responseJSON[quotesIndex]?.customerQuotes}
                                index={1}
                            />
                        </div>
                    </div>
                    </>
                )}
                <div className='d-flex flex-row justify-content-between align-items-stretch w-100 mt-3'>
                    <div className='px-2' style={{width:'35%',height:200}}>
                        <MeetingAttended />
                    </div>
                    <div className='px-2' style={{width:'65%',height:200}}>
                        <Insights />
                    </div>
                </div>
            </div> 
            
            {/* Distilled features */}
            {responseJSON?.length !== 0 && 
            <>
            <div className='py-3 px-4 d-flex flex-column justify-content-start align-items-center w-100 my-3' style={{
                // backgroundColor: colors.white02,
                backgroundColor: '#F7FAFA',
                borderRadius:12,
            }}>
                <div className='d-flex flex-row justify-content-between align-items-center w-100 mb-2'>
                    <h4 style={{fontSize:20,fontWeight:700,color:colors.black}}>
                        {'Distilled Features picked for you'}
                    </h4>
                    <img src={images.ArrowBlack} alt='' style={{height:24,width:24,objectFit:'contain'}}></img>
                </div>
                <FeaturesTable responseJSON={responseJSON}/>
            </div>
            </>
            }
             

            {/* Tasks */}
            {responseJSON?.length !== 0 && 
            <>
            <div className='py-3 px-4 d-flex flex-column justify-content-start align-items-center w-100 my-3' style={{
                // backgroundColor: colors.white02,
                backgroundColor: '#F7FAFA',
                borderRadius:12,
            }}>
                <div className='d-flex flex-row justify-content-between align-items-center w-100 mb-2'>
                    <h4 style={{fontSize:20,fontWeight:700,color:colors.black}}>
                        {'Tasks'}
                    </h4>
                    <img src={images.ArrowBlack} alt='' style={{height:24,width:24,objectFit:'contain'}}></img>
                </div>
                <RoadmapTable />
            </div>
            </>
            }
              
        </div>
        
    </div>
    </>
  )
}

export default Body