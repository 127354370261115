import type from '../Config/apiTypeConstants.ts';
import endpoint from '../Config/apiEndPointConstants.ts';
import { axiosInstance } from '../Config/axiosConfig.ts';
import { toast } from 'react-toastify';


export const loginADMIN = (data: any) => {
    return axiosInstance.post(type.LOGIN, data)
    .then(function (response) {
        return response.data;
    })
    .then((response) => {
        return response;
    })
    .catch((err) => {
        toast.error(err?.response?.data?.message);
        console.log("err in userSpecific report api", err);
        return null;
    });
  };
  
export const uploadFile = async (
    data: FormData,
    token: string
): Promise<any | undefined> => {
return axiosInstance
    .post(type.OPENAI + endpoint.UPLOADFILE, data, {
    headers: {
        "Authorization": token,
    },
    })
    .then(function (response) {
    return response.data;
    })
    .then((response) => {
    return response;
    })
    .catch((err) => {
    toast.error(err?.response?.data?.message);
    console.log("err in userSpecific report api", err);
    return null;
    });
};