import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import images from "../Utils/images";
import { colors } from "../Utils/colors.ts";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  ["0", "Create New", "", "", "", ""],
  ["1", "Review PRD's", "18/09", "+ Add", "only me", "highest"],
  ["2", "Review PRD's", "18/09", "+ Add", "only me", "highest"],
  ["3", "Review PRD's", "18/09", "+ Add", "only me", "highest"],
  ["4", "Review PRD's", "18/09", "+ Add", "only me", "highest"],
  ["5", "Review PRD's", "18/09", "+ Add", "only me", "highest"],
];

export default function RoadmapTable() {
  return (
    <TableContainer component={Paper} className="roadmaptable" elevation={0}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <img 
                src={images.TickCircleGrey} 
                style={{
                  height:20,width:20,objectFit:"contain"
                }}
              ></img>
            </TableCell>
            <TableCell align="left" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>{'Task'}</TableCell>
            <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>{'Due Date'}</TableCell>
            <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>{'Project'}</TableCell>
            <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>{'Visibility'}</TableCell>
            <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>{'Priority'}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>
              
            </TableCell>
            <TableCell>Task</TableCell>
            <TableCell align="right">Due Date</TableCell>
            <TableCell align="right">Project</TableCell>
            <TableCell align="right">Visiblity</TableCell>
            <TableCell align="right">Priority</TableCell>
          </TableRow> */}
        </TableHead>
        <TableBody>
          {rows.map((row)=>{
            return(
              <TableRow key={row[0]} style={{}}>
                <TableCell align="center">
                  <img 
                    src={images.TickCircleGrey} 
                    style={{
                      height:20,width:20,objectFit:"contain"
                    }}
                  ></img>
                </TableCell>
                <TableCell align="left" style={{fontSize:14,fontWeight:400,color:colors.black,paddingBlock:10}}>{row[1]}</TableCell>
                <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.black,paddingBlock:10}}>{row[2]}</TableCell>
                <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.linkblue,paddingBlock:10}}>{row[3]}</TableCell>
                <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.black,paddingBlock:10}}>{row[4]}</TableCell>
                <TableCell align="center" style={{paddingBlock:10}}>
                  <div
                    style={{
                      display: row[1] === "Create New" ? "none" : "flex",
                      borderRadius: 6,
                      background: "#D20000",
                      padding: "5px 4px",
                      justifyContent: "center",
                      gap: "10px",
                      color: "#fff",
                      marginTop: "10px",
                      textTransform: "capitalize",
                    }}
                  >
                    {row[5]}
                  </div>
                  {/* <div className="py-2 px-3 bg-danger br-2">
                    <p style={{fontSize:14,fontWeight:400,color:colors.white}}>
                      {row[5]}
                    </p>
                  </div> */}
                </TableCell>
              </TableRow>
            )
          })}
          {/* {rows.map((row) => (
            <TableRow key={row[0]} className="row">
              <TableCell>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12Z"
                    fill="#666666"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z"
                    fill="#666666"
                  />
                </svg>
              </TableCell>
              <TableCell align="right">{row[2]}</TableCell>
              <TableCell align="right">
                {
                  <a
                    href="/add"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    +Add
                  </a>
                }
              </TableCell>
              <TableCell align="right">{row[4]}</TableCell>
              <TableCell align="right">{row[5]}</TableCell>
              <TableCell align="right">
                <div
                  style={{
                    display: row[2] === "Create New" ? "none" : "flex",
                    borderRadius: 6,
                    background: "#D20000",
                    padding: "5px 4px",
                    justifyContent: "center",
                    gap: "10px",
                    color: "#fff",
                    marginTop: "10px",
                    textTransform: "capitalize",
                  }}
                >
                  {row[6]}
                </div>
              </TableCell>
            </TableRow>
          ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
