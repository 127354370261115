import React from "react";

import { Sidebar } from "../../Components/Sidebar.tsx";
import { PageTitle } from "../../Components/PageTitle.tsx";

const Integrations = () => {
    return (
        <div className="page d-flex flex-row justify-content-between align-items-start" style={{
            height:'100vh',
            width:'100%',
            background: 'linear-gradient(180deg, rgba(228,255,200,1) 10%, rgba(255,255,255,1) 70%)',
            overflowY: "hidden",
        }}>
            <Sidebar />
            <div className="d-flex flex-column justify-content-start align-items-start" style={{
                flex:1,
                height:'100%',
                paddingInline:40,
                paddingBlock:32,
            }}>
                <PageTitle title="Integrations" />
                <div className="pe-2 verythinscrollnewinv w-100"
                    style={{
                        // margin: "auto",
                        marginBottom: "20px",
                        marginTop: "20px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        gap:21,
                        overflowY:"auto",
                        flex:1,
                    }}
                >
                    <img
                        src="../../images/int1.png"
                        alt="Integrations"
                        style={{
                        width: "100%",
                        height: "auto",
                        }}
                    />
                    <img
                        src="../../images/int2.png"
                        alt="Integrations"
                        style={{
                        width: "100%",
                        height: "auto",
                        }}
                    />
                    <img
                        src="../../images/int3.png"
                        alt="Integrations"
                        style={{
                        width: "100%",
                        height: "auto",
                        }}
                    />
                </div>
            </div>
        </div>
    );
    }
    
    export { Integrations };