import React from "react";
import "./Components.scss";
import { Link, useLocation } from "react-router-dom";

const MenuItem = ({
  name,
  icon,
  link,
  active,
  centered,
}: {
  name: string;
  icon: string;
  link: string;
  active?: boolean;
  centered?: boolean;
}) => {
  const location = useLocation();
  const isActive = location.pathname === link;
  return (
    <Link
      to={link}
      className={`menu-item ${isActive || active ? "active" : ""}`}
      style={{
        textAlign: centered ? "center" : "left",
        display: "block", // Ensure the Link responds to text alignment
        pointerEvents: (name !== 'Dashboard' && name !== 'roadmap' && name !== 'integrations') ? "none" : "auto",
      }}
    >
      <i className={`icon-${icon}`}></i>
      <div className="name">{name}</div>
    </Link>
  );
};

const menuItems = [
  {
    name: "Dashboard",
    icon: "dashboard",
    link: "/dashboard",
  },
  {
    name: "roadmap",
    icon: "roadmap",
    link: "/roadmap",
  },
  {
    name: "integrations",
    icon: "integrations",
    link: "/integrations",
  },
  {
    name: "research AI",
    icon: "research AI",
    link: "/research AI",
  },
  // {
  //   name: "settings",
  //   icon: "settings",
  //   link: "/settings",
  // },
];

const menuItems2 = [
  {
    name: "Support",
    icon: "support",
    link: "/support",
  },
  {
    name: "Reports",
    icon: "reports",
    link: "/reports",
  },
  {
    name: "Settings",
    icon: "settings",
    link: "/settings",
  },
];

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="upper">
        <h2 className="title">Klarytics</h2>
        <p className="menu">Menu</p>
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            name={item.name}
            icon={item.icon}
            link={item.link}
          />
        ))}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="214"
          height="2"
          viewBox="0 0 214 2"
          fill="none"
           className="divider"
        >
          <path opacity="0.3" d="M0 1H214" stroke="#B3B3B3" />
        </svg>
        {menuItems2.map((item, index) => (
          <MenuItem
            key={index}
            name={item.name}
            icon={item.icon}
            link={item.link}
          />
        ))}
      </div>
      <div className="lower">
        <MenuItem
          name="Upgrade to Pro"
          icon="upgrade"
          link="/upgrade"
          active
          centered
        />
                <svg
          xmlns="http://www.w3.org/2000/svg"
          width="214"
          height="2"
          viewBox="0 0 214 2"
          fill="none"
          className="divider"
        >
          <path opacity="0.3" d="M0 1H214" stroke="#B3B3B3" />
        </svg>
        <MenuItem name="Logout" icon="logout" link="/logout" centered/>
      </div>
    </div>
  );
};

export { Sidebar };
