import React, { useState } from 'react'
import { colors } from '../../Utils/colors.ts'
import images from '../../Utils/images.js'
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { loginADMIN } from '../../Services/index.ts';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext.tsx';

const Auth = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState('');
  const [password,setPassword] = useState('');
  const {login} = useAuth();

  const loginAction = () => {
    let data = {
      email: userId,
      password: password,
    }
    loginADMIN(data).then((res)=>{
      if(res?.flag){
        login(res?.data);
        navigate('/dashboard')
      }else{
        toast.error('Invalid Credentials')
      }
    })
  }

  return (
    <>
    <div className='d-flex flex-row justify-content-between align-items-center' style={{
        height:'100vh',	        
        width:'100vw',
        backgroundColor:colors.white02,
    }}>	  
      <div className='d-flex flex-column justify-content-around align-items-center w-50 h-100 px-4 py-3' style={{
        backgroundColor:colors.green01,
      }}>
        <div className='d-flex flex-row justify-content-center align-items-center my-3'>
          <img src={images.LogoMain} alt='logo_main'
          style={{
            height:60,
            width:60,
            objectFit:'contain',
          }}/>
          <h1 style={{fontSize:52,fontWeight:800,color:colors.white01,marginInlineStart:16}}>
            {'Klarytics'}
          </h1>
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 my-3' 
          style={{flex:1,backgroundColor:'#ff4',maxHeight:'40vh'}}
        >
          <ReactPlayer 
            url={'https://youtu.be/Iws_HHAMr-c'}
            height='100%'
            width='100%'
            controls={true}
            playing={true}
            muted
          />
        </div>
        <div className='d-flex flex-column justify-content-center align-items-center w-100 mt-2 mb-3'>
          <h3 style={{fontSize:34,fontWeight:800,color:colors.white01,textAlign:'center'}}>
            {/* {'Helping Product Teams be as '} */}
            <span style={{color:colors.green02}}>
              {'Helping Product Teams '}
            </span>
            {'be as customer aware as CX Teams'}
          </h3>
          <p className='' style={{fontSize:24,fontWeight:400,color:colors.white01,marginBlockStart:40}}>
            {'RESEARCH | ANALYZE | CREATE'}
          </p>
        </div>
      </div>
      <div className='d-flex flex-column justify-content-center align-items-center w-50 h-100 px-4 py-3' style={{
        backgroundColor:colors.white01
      }}>
        <div className='shadow-sm d-flex flex-column justify-content-between align-items-center' style={{
          // flex:1,
          width: 'min(500px, 70%)',
          height: 'min(600px,80vh)',
          backgroundColor:colors.white02,
          borderRadius:16,
          paddingBlock:32,
          paddingInline:40,
        }}>
          <h4 style={{fontSize:20,fontWeight:700,color:colors.black,width:'100%'}}>
            {'Login to Klarytics'}
          </h4>
          <div className='d-flex flex-column justify-content-start align-items-center w-100' 
            style={{
              flex:1,
              marginBlock:16
            }}
          >
            <TextField
              placeholder='User Id'
              value={userId}
              onChange={(e)=>{setUserId(e.target.value)}}
              type='text'
              size='small'
              style={{marginBlock:8,width:'100%'}}
            />
            <TextField
              placeholder='Password'
              value={password}
              onChange={(e)=>{setPassword(e.target.value)}}
              type='password'
              size='small'
              style={{marginBlock:8,width:'100%'}}
            />
          </div>
          <Button 
            variant="contained"
            style={{
              width:'100%',
              textTransform:'none',
              backgroundColor:colors.green01,
              color:colors.white,
            }}
            onClick={()=>{
              loginAction();
              // navigate('/dashboard')
            }}
            disabled={userId && password ? false : true}
          >
            {'Login to Dashboard'}
          </Button>
        </div>
      </div>
    </div>
    </>
  )
}

export default Auth