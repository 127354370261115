import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import images from "../Utils/images";
import { colors } from "../Utils/colors.ts";
import { useNavigate } from 'react-router-dom';

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
//   ["0", "Tick", "Create New", "", "", "", ""],
  ["1", "tick", "Feature Name", "+ Add deals", "+34", "+ Add roadmaps"],
  ["2", "tick", "Feature Name", "+ Add deals", "+34", "+ Add roadmaps"],
  ["3", "tick", "Feature Name", "+ Add deals", "+34", "+ Add roadmaps"],
  ["4", "tick", "Feature Name", "+ Add deals", "+34", "+ Add roadmaps"],
  ["5", "tick", "Feature Name", "+ Add deals", "+34", "+ Add roadmaps"],
];

export default function FeaturesTable({responseJSON}: any) {

  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} className="featuresTable" elevation={0}>
      <Table size="small">
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <img 
                      src={images.TickCircleGrey} 
                      style={{
                        height:20,width:20,objectFit:"contain"
                      }}
                    ></img>
                </TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Status</TableCell>
                <TableCell align="left" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Name</TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Revenue Impact</TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Occurrence</TableCell>
                <TableCell align="center" style={{fontSize:16,fontWeight:600,paddingBlock:16}}>Road map</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {responseJSON && responseJSON?.map((item, index)=>{
                return(
                    <TableRow key={index}>
                        <TableCell align="center">
                            <img 
                                src={images.TickCircleGrey} 
                                style={{height:20,width:20,objectFit:"contain"}}
                            ></img>
                        </TableCell>
                        <TableCell align="center">
                            <img 
                                src={images.CircleFillGreen} 
                                style={{height:16,width:16,objectFit:"contain"}}
                            ></img>
                        </TableCell>
                        <TableCell align="left" style={{fontSize:14,fontWeight:400,color:colors.black,paddingBlock:10}}>{item?.featureName}</TableCell>
                        <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.linkblue,paddingBlock:10}}>{'+ Add deals'}</TableCell>
                        <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.black,paddingBlock:10}}>{item?.numberOfTimesMentioned}</TableCell>
                        <TableCell align="center" style={{fontSize:14,fontWeight:400,color:colors.linkblue,paddingBlock:10,cursor:"pointer"}} onClick={()=>{
                          navigate('/integrations');
                        }}>
                          {'+ Add to roadmaps'}
                        </TableCell>
                    </TableRow>
                )
            })}
        </TableBody>
        {/* <TableBody>
          {rows.map((row) => (
            <TableRow key={row[0]} className="row">
              <TableCell>{row[0]}</TableCell>
              <TableCell>{row[1]}</TableCell>
              <TableCell>{row[2]}</TableCell>
              <TableCell>{row[3]}</TableCell>
              <TableCell>{row[4]}</TableCell>
              <TableCell>{row[5]}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}
      </Table>
    </TableContainer>
  );
}
