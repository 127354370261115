import React from 'react'
import Body from './Body.jsx'
import { Sidebar } from '../../Components/Sidebar.tsx'

const Dashboard = () => {
  return (
    <div className='d-flex flex-row justify-content-between align-items-start' style={{
      height:'100vh',
      width:'100%',
      background: 'linear-gradient(180deg, rgba(228,255,200,1) 10%, rgba(255,255,255,1) 70%)',
      overflowY: 'hidden',
    }}>
      <Sidebar />
      <Body />
    </div>
  )
}

export default Dashboard