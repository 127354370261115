export const colors = {
  white: "#fff",
  black: "#000",
  themeBlue: "#A470FF",
  themeDarkBlue: "#8E53F7",
  grey: "#F2F2F2",
  grey2: "#e3e3e3",
  darkgrey: "#797979",
  textChrome: "#FEAF52",
  green: "#04B100",
  darkbluetext: "#300F61",
  black04: "#262626",
  white01: "#FFFFFF",
  success01: "#49B147",
  success03: "#04B100",
  linkblue: "#3366CC",
  primary03: "#9357FF",
  primary01: "#ECE1FF",
  primary04: "#5A3897",
  greys01: "#808080",
  greys03: "#B3B3B3",
  greys04: "#666666",
  background: "#717171",
  error03: "#FF0000",
  black02: "#0D0D0D",
  blackHalf2: "#0D0D0D20",
  blackHalf: "#0D0D0D50",
  borderwhite: "#E6E6E6",
  white04: "#F0F0F0",
  white03: "#F5F5F5",
  white02: "#FAFAFA",
  red49: "#FF4D49",
  black03: "#1A1A1A",
  error04: "#E50000",
  warning04: "#E59700",
  yellow03: "#FECD54",
  blue03: "#0043A8",
  yellow04: "#FF9900",
  lightPurple: "#ECE1FF",
  darkPurple: "#8E53F7",
  green01: '#064528',
  green02: '#C6F738',
};
