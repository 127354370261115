import React from 'react'
import { colors } from '../../../Utils/colors.ts'
import images from '../../../Utils/images.js'

const IItem = ({number1, number2, text, imagesrc, borderR=true}) => {
    return(
        <>
        <div className='d-flex flex-column justify-content-start align-items-start px-2' style={{flex:1,borderRight: borderR ? `2px solid ${colors.borderwhite}` :''}}>
            <div className='d-flex flex-row justify-content-start align-items-center w-100'>
                <p style={{fontSize:40,fontWeight:600,color:colors.green02}}>
                    {number1}
                </p>
                <p style={{fontSize:13,fontWeight:400,color:colors.black04}}>
                    {number2}
                </p>
            </div>
            <div className='d-flex flex-row justify-content-start align-items-center w-100'>
                <img src={imagesrc} style={{height:20,width:20,objectFit:'contain',marginInlineEnd:8}}></img>
                <p style={{fontSize:13,fontWeight:400,color:colors.greys04,margin:0}}>
                    {text}
                </p>
            </div>
        </div>
        </>
    )
}

const Insights = () => {
  return (
    <>
    <div className='d-flex flex-column justify-content-around align-items-center w-100 h-100' style={{
        backgroundColor:colors.white,
        borderRadius:12,
        paddingBlock:16,
    }}>
        <div className='d-flex flex-row justify-content-between align-items-center w-100 pb-2 px-4' style={{
            borderBottom:`1px solid ${colors.borderwhite}`
        }}>
            <h4 style={{fontSize:20,fontWeight:600,color:colors.black}}>
                {'Insights'}
            </h4>
            <p style={{fontSize:13,fontWeight:400,color:colors.black04}}>
                {'last 24 hours'}
            </p>
        </div>
        <div className='d-flex flex-row justify-content-between align-items-center w-100 my-2 px-4' style={{
        }}>
            <IItem 
                number1={'12'}
                number2={'-14% '}
                text={'Tasks Created'}
                imagesrc={images.CheckSquare}
            />
            <IItem 
                number1={'12'}
                number2={'-14% '}
                text={'Request raised'}
                imagesrc={images.PlusCircle}
            />
            <IItem 
                number1={'12'}
                number2={'-14% '}
                text={'Feedbacks'}
                imagesrc={images.GoldStar}
                borderR={false}
            />
        </div>
    </div>
    </>
  )
}

export default Insights