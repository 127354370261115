import React from 'react'
import { colors } from '../../../Utils/colors.ts'
import images from '../../../Utils/images.js'

const MeetingAttended = () => {
  return (
    <>
    <div className='d-flex flex-column justify-content-around align-items-center w-100 h-100' style={{
        backgroundColor:colors.white,
        borderRadius:12,
        paddingBlock:16,
    }}>
        <div className='d-flex flex-row justify-content-between align-items-center w-100 pb-2 px-4' style={{
            borderBottom:`1px solid ${colors.borderwhite}`
        }}>
            <h4 style={{fontSize:20,fontWeight:600,color:colors.black}}>
                {'Meetings Attended'}
            </h4>
            <img src={images.ArrowBlack} style={{height:16,width:16,objectFit:'contain'}}></img>
        </div>
        <div className='d-flex flex-row justify-content-between align-items-center w-100 my-2 px-4' style={{
        }}>
            <p style={{fontSize:40,fontWeight:600,color:colors.green01}}>
                {'5782'}
            </p>
            <p style={{fontSize:13,fontWeight:400,color:colors.greys04}}>
                {'Google Meet | Zoom Calls | Skype'}
            </p>
        </div>
        <div className='d-flex flex-row justify-content-start align-items-center w-100 px-4'>
            <p style={{fontSize:13,fontWeight:400,color:colors.black04}}>
                {'-14% from last 48 hours'}
            </p>
        </div>
        
    </div>
    </>
  )
}

export default MeetingAttended