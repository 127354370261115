import axios from "axios";

// ************ DEVELOPMENT URL ************
//  export const BASE_URL = 'https://dev.api.cheerio.in/'; //Development url Live
//  export const SOCKET_URL = 'https://dev.api.cheerio.in/';

// ************ localhost ************
export const BASE_URL = `https://zb5qarphvg.execute-api.ap-south-1.amazonaws.com/SFB/`;
export const SOCKET_URL = "http://localhost:3001/";

// ************ PROD ************
// export const BASE_URL = `https://pre-prod.cheerio.in:3443/`;
// export const SOCKET_URL = 'https://pre-prod.cheerio.in:3443/';

// ************ LIVE APP URL ************
//  export const BASE_URL = process.env.REACT_APP_BACKEND_URL; //Live App URL (With / at the end in .env file)
//  export const SOCKET_URL = process.env.REACT_APP_BACKEND_URL;

export const BASE_IMAGE_URL = `${BASE_URL}api/s3/downloadImage/`; //Live
export const BASE_CHAT_IMAGE_URL = `${BASE_URL}api/s3/chat/`;
export const BASE_VIDEO_URL = `${BASE_URL}api/s3/chat/`; //`https://cheerio-preprod.s3.ap-south-1.amazonaws.com/`
export const GOOGLE_KEY = "AIzaSyAe65EkkTM4siKKdykFaZtu4B8jiRC4mbQ"; // 'AIzaSyB1if3Nly8iNRr9LhfuZmoHGnT3uqXE6nA'

var headers = {
  // Accept: 'application/json',
  "Content-Type": "application/json",
};

// export default function axiosApi() {
//     var headers = {
//       // Accept: 'application/json',
//       'Content-Type': 'application/json',
//     };

//     return axios.create({
//       // baseURL : "http://192.168.1.8:3000/api/",
//       baseURL: `${BASE_URL}api/`,
//       headers: headers,
//       timeout: 120000,
//     });
//   }

//   export function axiosConfigApi() {
//     var headers = {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     };

//     return axios.create({
//       // baseURL : "http://192.168.1.8:3000/api/",
//       baseURL: `${BASE_URL}api/`,
//       headers: headers,
//       timeout: 120000,
//     });
//   }

export const axiosInstance = axios.create({
  // baseURL : "http://192.168.1.8:3000/api/",
  baseURL: `${BASE_URL}api/`,
  headers: headers,
  timeout: 120000,
});
