import React from 'react'
import { colors } from '../../../Utils/colors.ts'

const Quotes = ({containerStyle , quotesData, index}) => {
  return (
    <>
    <div className='w-50 h-100' style={{...containerStyle}}>
        <div className='d-flex flex-column justify-content-start align-items-center w-100 h-100' style={{
            backgroundColor: colors.white01,
            borderRadius:12,
            paddingBlock:20,
            paddingInline:32,
        }}>
            <h4 className='mb-2' style={{fontSize:16,fontWeight:500,color:colors.black,width:'100%'}}>
                {`Customer quote ${index+1}`}
            </h4>
            <p style={{fontSize:15,fontWeight:400,color:colors.greys04,width:'100%'}}>
                {`${quotesData[index]}`}
            </p>
        </div>
    </div>
    </>
  )
}

export default Quotes